import React, { useState, useEffect } from 'react'
import Moment from 'react-moment';
import { Table, Pagination } from 'react-bootstrap';
import { SpanProcessing, SpanSuccess, TdLog } from './styles';

import { getQueue } from '../../services/Queue';

export default function Queue({ refresh }) {
  const [items, setItems] = useState([]);
  const [pageActive, setPageActive] = useState(1);
  const [pages, setPages] = useState([]);

  const getQueueList = async (page = 1) => {
    const data = await getQueue(page, 30);
    setItems(data);

    setQntPages(data.total);
    setPageActive(page);
  };

  const setQntPages = (total = 0) => {
    if (!total) {
      return;
    }

    let qntPages = [];
    for (let number = 0; number <= (total/30); number++) {
      qntPages.push(number);
    }

    setPages(qntPages);
  };

  useEffect(() => {
    getQueueList();

    setInterval(getQueueList, 30000);
  }, []);

  useEffect(() => {
    getQueueList();
  }, [refresh]);

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nome da Fila</th>
            <th>Email de Importação</th>
            <th>Mensagem de Log</th>
            <th>Status da Fila</th>
            <th>Criada em</th>
            <th>Finalizada em</th>
          </tr>
        </thead>
        <tbody>
          {items && items.data && items.data.map((item, index) => 
            <tr key={index}>
              <td>{item.queue}</td>
              <td>{item.recipientClient ? item.recipientClient.email : ''}</td>
              <TdLog>{item.log}</TdLog>
              <td>{item.status == 0 ? <SpanSuccess>Finalizada</SpanSuccess>: <SpanProcessing>Processando</SpanProcessing>}</td>
              <td><Moment format="DD/MM/YYYY - hh:mm:ss">{item.created}</Moment></td>
              <td><Moment format="DD/MM/YYYY - hh:mm:ss">{item.updated}</Moment></td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}
